.table-row-active {
  background-color: lavender;
}

.table-row-deactive {
  background-color: aliceblue;
}

.active-text-color {
  /* color: #f26649;
  border-color: #f26649; */
  border-bottom-style: solid;
  border-bottom-color: #005dab;
}

.notactive-text-color {
  /* color: #f26649;
  border-color: #f26649; */
  border-bottom-style: solid;
  border-bottom-color: #c1c1c1;
}

/* input {
  accent-color: green;
} */

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Styling checkbox */
  width: 12px;
  height: 12px;
  background-color: red;
}

input[type="checkbox"]:checked {
  background-color: #2bd144;
}
input[type="text"]:focus-visible{
  outline: none !important;
}