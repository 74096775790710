.container {
  text-align: center;
  justify-content: space-between;
  display: flex;
  padding: 10px;
}
.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
  /* top: 8px; */
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  width: 80px;
  height: 27px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "YES";
  padding-left: 13px;
  background-color: #0d6efd;
  color: #fff;
  margin-top: -5px;


}
.inner:after {
  content: "NO";
  padding-right: 13px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
  margin-top: -5px;

}
.switch {
  display: block;
  width: 14px;
  height: 14px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}
