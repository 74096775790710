
$font-family-sans-serif: 'Open Sans Regular';
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
$primary:       #005DAB !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
$link-color: $blue;
$link-decoration: none !default;
$small-font-size: .8em !default;
$font-size-root: 14px !default;
$btn-border-width: 0px;
$input-btn-focus-box-shadow:none !default;
$font-weight-bold: 600 !default;
$quantity: 15;
$n:300;
$table-cell-padding-x: 1rem;
$table-cell-padding-y: 0.75rem;

@font-face {
    font-family: 'Open Sans Regular';
    src: url('../webfonts/OpenSans-Regular.eot');
    src: url('../webfonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/OpenSans-Regular.woff2') format('woff2'),
        url('../webfonts/OpenSans-Regular.woff') format('woff'),
        url('../webfonts/OpenSans-Regular.ttf') format('truetype'),
        url('../webfonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Semibold';
    src: url('../webfonts/OpenSans-SemiBold.eot');
    src: url('../webfonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../webfonts/OpenSans-SemiBold.woff') format('woff'),
        url('../webfonts/OpenSans-SemiBold.ttf') format('truetype'),
        url('../webfonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

$shadows-small:  multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big:    multiple-box-shadow(100);

@import 'bootstrap/bootstrap.scss';
@import '../css/all.min.css';

@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #000';
  @for $i from 2 through $n{
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #000';
}
@return unquote($value)
}
html, body, #root{
    min-height: 100%;
    height: 100%;
    position: relative;
    
}

.nav-icon{
    min-width: 24px;
    // min-height: 24px;
    display: flex !important;
    justify-self: center;
    justify-content: center;
}

.cursor-pointer{
    cursor: pointer !important;
}

.fw-bold, th, .badge{
    font-family: 'Open Sans Semibold' !important;
    font-weight: 600 !important;
}

.table>:not(:first-child){
    border-top: none;
}

#stars{
    width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation			: animStar 50s linear infinite;
    
  &:after{
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
    
}
    
#stars2{
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
    animation : animStar 100s linear infinite;
      
    &:after{
        content: " ";
      position: absolute;
      top: 2000px;
      width: 2px;
      height: 2px;
      background: transparent;
      box-shadow: $shadows-medium;
    }
}
    
#stars3{
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
    animation : animStar 150s linear infinite;
    
  &:after{
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
    
}

@keyframes animStar{
    from	{
  
      transform: translateY(0px)
    }
    to		{
      transform: translateY(-2000px)
    }
}
  
// .search-field{
//   width: 100%;
// }

@media (max-width:576px){
  .page-link {
    padding: 0.375rem 0.375rem;
}
.tabs_user{
  margin-right: 0px !important;
}
.settings_th{
  padding: 16px 8px !important;
}
}
@media screen and (max-width: 600px) {
  .login-bg{
    background-image: none !important;
  }
  .login{
    width:100% !important;
  }
  .search-field{
  width: 100%;
}
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .search-box-style {
    width: 35vw;
  }
  
}
@media screen and (min-width: 1201px) and (max-width: 2560px) {
  .search-box-style {
    width: 24vw;
  }
}
@media screen and (max-width: 500px) {
  .search-box-style {
    width: 100%;
  }
}

@media screen and (max-width: 992px) and (min-width: 600px) {
  .login{
    width:50% !important;
  }
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba($secondary, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba($secondary, 0.3);
  -o-box-shadow: inset 0 0 6px rgba($secondary, 0.3);
  box-shadow: inset 0 0 6px rgba($secondary, 0.3);
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: $secondary;
  border-radius: 20px;
}